import { colors, typography } from 'assets/styles';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import NextLink from 'next/link';
import { imageToBackgroundImageCss } from 'utils/parse-image';

const LinkStyle = css`
  ${({ $image }) => imageToBackgroundImageCss($image)};
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 1.2rem;
  height: 16rem;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  border-radius: 1.2rem;
  overflow: hidden;
  transform: translateZ(0); // safari fix for rounded borders
  transition: transform 200ms ease-in-out;

  ${typography.h4};
  color: ${colors.white};
  text-decoration: none;

  @media (any-hover: hover) {
    &:hover {
      transform: translateZ(0) translateY(-0.4rem);
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({ $isDark }) =>
      $isDark
        ? `linear-gradient(180deg, ${rgba(colors.black, 0)} 33%, ${rgba(colors.black, 0.6)} 100%);`
        : `linear-gradient(180deg, ${rgba(colors.gradientBrown, 0)} 33%, ${rgba(colors.gradientBrown, 0.6)} 100%)`};
`;

export const Link = styled(NextLink)`
  ${LinkStyle};
`;

export const ExternalLink = styled.a`
  ${LinkStyle};
`;

export const Label = styled.span`
  position: relative;
`;
