import React, { ComponentProps } from 'react';
import { ImageSet } from 'models/image';
import { Container, Content, SubTitle, Title, Completed, CompletedIcon, ProgressBar } from './program-card.style';

export type ProgramCardProps = ComponentProps<'article'> & {
  title: string;
  subTitle?: string;
  image: ImageSet;
  wideImage: ImageSet;
  progress?: number;
  total?: number;
  completedTitle?: string;
  isDark?: boolean;
};

const ProgramCard: React.FC<ProgramCardProps> = ({
  image,
  wideImage,
  subTitle,
  title,
  progress,
  total,
  completedTitle,
  isDark,
}) => {
  const completed = progress === total;

  return (
    <Container $isDark={isDark} $image={image} $wideImage={wideImage}>
      <Content>
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
        <Title>{title}</Title>
        {completed && completedTitle ? (
          <Completed>
            <CompletedIcon />
            {completedTitle}
          </Completed>
        ) : (
          <ProgressBar $progress={progress} $total={total} />
        )}
      </Content>
    </Container>
  );
};

export default ProgramCard;
