import React, { ComponentProps } from 'react';
import NextLink from 'next/link';
import { Course } from 'models/course';
import { useSelectorWithProps } from 'domains/redux/utils';
import { useIntl } from 'domains/intl';
import { Selectors as programsSelectors } from 'logic-domains/programs';
import Grid from 'components/grid';
import Section from 'components/section';
import SectionHeader from 'components/section-header';
import Carousel from 'components/carousel';
import ProgramCard from 'components/program-card';
import { MobileContainer, TabletContainer, Anchor } from './programs-slider.style';

type ProgramItemProps = ComponentProps<'a'> & {
  item: Course;
  isDark?: boolean;
};

const ProgramItem: React.FC<ProgramItemProps> = ({ item, isDark }) => {
  const { formatMessage } = useIntl();
  const { total, progress } = useSelectorWithProps(programsSelectors.getProgramProgressByUid, { uid: item.uid });

  return (
    (item.url && item.title && item.image && (
      <NextLink legacyBehavior href={item.url} passHref>
        <Anchor>
          <ProgramCard
            title={item.title}
            image={item.image}
            wideImage={item.imageWide}
            progress={progress}
            total={total}
            completedTitle={formatMessage('course.completed')}
            isDark={isDark}
          />
        </Anchor>
      </NextLink>
    )) ||
    null
  );
};

export type ProgramsSliderProps = ComponentProps<'section'> & {
  isDark?: boolean;
  isFirst?: boolean;
  title: string;
  moreLink?: string;
  moreLabel?: string;
  items: Course[];
};

const ProgramsSlider: React.FC<ProgramsSliderProps> = ({ isDark, isFirst, title, moreLabel, moreLink, items }) => (
  <Section isFirst={isFirst}>
    <Grid columns="mb:1">
      <SectionHeader title={title} linkLabel={moreLabel} link={moreLink} isDark={isDark} />
    </Grid>
    <MobileContainer>
      <Grid columns="mb:1">
        <Carousel isDark={isDark} hideArrows>
          {items.map((program) => (
            <ProgramItem key={program.uid} item={program} isDark={isDark} />
          ))}
        </Carousel>
      </Grid>
    </MobileContainer>
    <TabletContainer>
      <Grid columns="mb:3">
        {items.map((program) => (
          <ProgramItem key={program.uid} item={program} isDark={isDark} />
        ))}
      </Grid>
    </TabletContainer>
  </Section>
);

export default ProgramsSlider;
