import styled from 'styled-components';
import { rgba } from 'polished';
import { DownloadedWhite } from 'assets/icons';
import { breakpointAndUp, colors, typography } from 'assets/styles';
import { imageToBackgroundImageCss, imageToBackgroundImageValues } from 'utils/parse-image';

export const Container = styled.article`
  ${({ $image }) => imageToBackgroundImageCss($image)};
  position: relative;
  height: 21.6rem;
  width: 100%;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  border-radius: 0.8rem;
  overflow: hidden;
  cursor: pointer;
  transform: translateZ(0); // safari fix for rounded borders
  transition: transform 200ms ease-in-out, filter 200ms ease-in-out;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${rgba(colors.black, 0.2)};
  }

  @media (any-hover: hover) {
    &:hover {
      transform: translateZ(0) translateY(-0.4rem);
    }
  }

  @media (${breakpointAndUp.tablet}) {
    height: 22.4rem;
    background-image: ${({ $wideImage }) => imageToBackgroundImageValues($wideImage)};
  }

  @media (${breakpointAndUp.desktop}) {
    height: 24rem;
    background-image: ${({ $wideImage }) => imageToBackgroundImageValues($wideImage)};
  }
`;

export const Content = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 1.6rem 1.6rem;
  color: ${colors.white};
`;

export const SubTitle = styled.h2`
  ${typography.caption};
  margin-bottom: 0.4rem;
  color: ${rgba(colors.white, 0.7)};
`;

export const Title = styled.h1`
  ${typography.h3};
  margin-bottom: 0.8rem;
`;

export const Completed = styled.span`
  ${typography.h5};
  display: flex;
  align-items: center;
`;

export const CompletedIcon = styled(DownloadedWhite)`
  margin-right: 0.8rem;
  font-size: 2.4rem;
  line-height: 62.5%;
`;

export const ProgressBar = styled.span`
  display: block;
  width: 100%;
  height: 0.6rem;
  border-radius: 0.6rem;
  background-color: ${rgba(colors.white, 0.2)};

  &::before {
    content: '';
    display: block;
    width: ${({ $progress, $total }) => ($total && !$progress ? '0.6rem' : `calc(100% / (${$total} / ${$progress}))`)};
    height: 100%;
    border-radius: 0.6rem;
    background-color: ${colors.white};
  }
`;
