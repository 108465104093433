import { BreakpointShorthands } from 'assets/styles';
import Grid from 'components/grid';
import LinkCard from 'components/link-card';
import Section from 'components/section';
import SectionHeader from 'components/section-header';
import { MenuLinks } from 'data/menus';
import { useIntl } from 'domains/intl';
import { useIsDark } from 'domains/user';
import { ImageSet } from 'models/image';
import React from 'react';

const yogaImageSet: ImageSet = {
  '1x': '/static/images/yoga-small.jpg',
  '2x': '/static/images/yoga-small@2x.jpg',
  '3x': '/static/images/yoga-small@3x.jpg',
};

const flowImageSet: ImageSet = {
  '1x': '/static/images/flow-small.jpg',
  '2x': '/static/images/flow-small@2x.jpg',
  '3x': '/static/images/flow-small@3x.jpg',
};

export type YogaFlowLinksProps = {
  title: string;
};

const YogaFlowLinks: React.FC<YogaFlowLinksProps> = ({ title }) => {
  const { formatMessage } = useIntl();
  const isDark = useIsDark();

  return (
    <Section hide={[BreakpointShorthands.Tablet, BreakpointShorthands.TabletLarge, BreakpointShorthands.Desktop]}>
      <Grid columns="mb:1">
        <SectionHeader title={title} isDark={isDark} />
      </Grid>
      <Grid columns="mb:2">
        <LinkCard url={MenuLinks.Yoga} image={yogaImageSet} label={formatMessage('home.yoga-flow.yoga.title')} />
        <LinkCard url={MenuLinks.Flow} image={flowImageSet} label={formatMessage('home.yoga-flow.flow.title')} />
      </Grid>
    </Section>
  );
};

export default YogaFlowLinks;
