import { Selectors as sectionsSelectors } from 'domains/sections';
import { Selectors as userSelectors } from 'domains/user';
import { Selectors as quotesSelectors } from 'domains/quotes';
import { createSelector } from 'reselect';

export const getLocalizedDailyQuotes = createSelector(
  userSelectors.getLocale,
  sectionsSelectors.getDailyQuotes,
  quotesSelectors.getQuotesMap,
  (locale, dailyQuotes, quotesMap) => dailyQuotes[locale]?.map((uid) => quotesMap[uid]),
);
