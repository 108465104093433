import YogaFlowLinks from 'layouts/yoga-flow-links';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { SessionSourceTypes } from 'models/session';
import { desktopMenuItems, MenuLinks, mobileMenuItems } from 'data/menus';
import { dayPartHeader, dayPartHeaderLoggedIn, dayPartHeaderThisMoment } from 'data/day-part-header';
import { LOCALES } from 'config/constants';
import { useIntl } from 'domains/intl';
import { Selectors as appSelectors } from 'domains/app';
import { useIsDark, usePartOfTheDay, Selectors as userSelectors } from 'domains/user';
import { Selectors as logicAppSelectors } from 'logic-domains/app';
import { Selectors as programsSelectors } from 'logic-domains/programs';
import { Selectors as quotesSelectors } from 'logic-domains/quotes';
import { useOnTrack } from 'logic-domains/music-player';
import { useTrackForYou } from 'logic-domains/tracking';
import Featured from 'layouts/featured';
import PageHead from 'layouts/page-head';
import PageHeader from 'layouts/page-header';
import PageLayout from 'layouts/page-layout';
import ProgramsSlider from 'layouts/programs-slider';
import QuoteSlider from 'layouts/quote-slider';
import Slider from 'layouts/slider';

const MeditationMap = dynamic(import('layouts/meditation-map'));

const Home: React.FC = () => {
  useTrackForYou();
  const router = useRouter();
  const { formatMessage } = useIntl();
  const isDark = useIsDark();
  const partOfDay = usePartOfTheDay();
  const user = useSelector(userSelectors.getUser);
  const courses = useSelector(programsSelectors.getLocalizedSectionPrograms);
  const programsProgress = useSelector(programsSelectors.getProgramsProgressMap);
  const meditationAmount = useSelector(appSelectors.getCount);
  const featured = useSelector(logicAppSelectors.getLocalizedSpotlight);
  const moment = useSelector(logicAppSelectors.getLocalizedMomentWithLabels);
  const dailyQuotes = useSelector(quotesSelectors.getLocalizedDailyQuotes);
  const recently = useSelector(logicAppSelectors.getLocalizedNewWithLabels);
  const trending = useSelector(logicAppSelectors.getLocalizedTrendingWithLabels);
  const { title, icon } = user ? dayPartHeaderLoggedIn[partOfDay] : dayPartHeader[partOfDay];
  const onMomentMusicTrack = useOnTrack(moment, SessionSourceTypes.ForYouThisMoment);
  const onRecentMusicTrack = useOnTrack(recently, SessionSourceTypes.ForYouRecent);
  const onTrendingMusicTrack = useOnTrack(trending, SessionSourceTypes.ForYouTrending);

  const programs = useMemo(
    () => courses.filter(({ uid }) => programsProgress[uid]?.total > programsProgress[uid]?.progress).slice(0, 3),
    [courses, programsProgress],
  );
  const startedPrograms = useMemo(
    () => !!Object.values(programsProgress ?? {}).filter(({ progress }) => progress).length,
    [programsProgress],
  );

  return (
    <PageLayout
      isDark={isDark}
      menuItems={desktopMenuItems}
      mobileMenuItems={mobileMenuItems}
      activeUrl={router.asPath}
      hasTabletHeader
    >
      <PageHead title={formatMessage('page.head.title.home')} />
      <PageHeader isDark={isDark} title={formatMessage(title, { name: user?.name })} icon={icon} />
      {!!featured?.length && <Featured isDark={isDark} items={featured.slice(0, 2)} isFirst />}
      {!!moment.length && (
        <Slider
          items={moment}
          source={SessionSourceTypes.ForYouThisMoment}
          title={formatMessage(dayPartHeaderThisMoment[partOfDay])}
          moreLabel={formatMessage('link.view-all')}
          moreLink={MenuLinks.ForYouThisMoment}
          onItem={onMomentMusicTrack}
          isDark={isDark}
        />
      )}
      {router.locale !== LOCALES.EN && <YogaFlowLinks title={formatMessage('home.yoga-flow.title')} />}
      {!!programs.length && (
        <ProgramsSlider
          items={programs}
          title={
            startedPrograms ? formatMessage('home.continue-learning.title') : formatMessage('home.start-learning.title')
          }
          moreLabel={formatMessage('home.continue-learning.more-label')}
          moreLink={MenuLinks.Learning}
          isDark={isDark}
        />
      )}
      {!!trending.length && (
        <Slider
          items={trending}
          source={SessionSourceTypes.ForYouTrending}
          title={formatMessage('home.trending.title')}
          moreLabel={formatMessage('link.view-all')}
          moreLink={MenuLinks.ForYouTrending}
          onItem={onTrendingMusicTrack}
          isDark={isDark}
        />
      )}
      {!!recently.length && (
        <Slider
          items={recently}
          source={SessionSourceTypes.ForYouRecent}
          title={formatMessage('home.new.title')}
          moreLabel={formatMessage('link.view-all')}
          moreLink={MenuLinks.ForYouNew}
          onItem={onRecentMusicTrack}
          isDark={isDark}
        />
      )}
      {!!dailyQuotes?.length && (
        <QuoteSlider
          isDark={isDark}
          title={formatMessage('home.daily-inspiration.title')}
          moreLabel={formatMessage('home.daily-inspiration.more-label')}
          moreLink={router.locale === LOCALES.NL ? MenuLinks.DailyInspirationNL : MenuLinks.DailyInspirationEN}
          items={dailyQuotes}
        />
      )}
      {meditationAmount ? (
        <MeditationMap isDark={isDark} amount={meditationAmount} paragraph={formatMessage('now-meditating')} />
      ) : null}
    </PageLayout>
  );
};

export default Home;
