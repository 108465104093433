import { CombinedState, createAsyncThunk } from '@reduxjs/toolkit';
import { Actions as audioTracksActions, Thunks as audioTracksThunks } from 'domains/audio-tracks';
import { Actions as courseActions, Selectors as courseSelectors } from 'domains/courses';
import { Actions as sectionsActions, Selectors as sectionsSelectors } from 'domains/sections';
import { Actions as userActions, Selectors as userSelectors, Thunks as userThunks } from 'domains/user';
import { Course } from 'models/course';
import { State as RootState } from 'models/redux-state';
import { Track } from 'models/track';
import Axios from 'utils/axios';
import { NAME } from './constants';
import * as Selectors from './selectors';

export const fetchPrograms = createAsyncThunk<void, void, { state: CombinedState<RootState> }>(
  `${NAME}/fetch-programs`,
  async (arg, thunkAPI) => {
    await thunkAPI.dispatch(audioTracksThunks.fetchNarrators());
    const locale = userSelectors.getLocale(thunkAPI.getState());
    const programs = sectionsSelectors.getLocalizedPrograms(thunkAPI.getState(), { locale });

    if (!programs) {
      const { data } = await Axios.get<{ courses: Course[]; tracks: Track[] }>('/api/programs', {
        params: { locale, includeAudioTracks: true },
      });
      thunkAPI.dispatch(courseActions.update(data.courses));
      thunkAPI.dispatch(audioTracksActions.updateTracks(data.tracks));
      thunkAPI.dispatch(
        sectionsActions.updatePrograms(
          locale,
          data.courses.map(({ uid }) => uid),
        ),
      );
    }
  },
);

export const fetchProgramBySlug = createAsyncThunk<boolean, string, { state: CombinedState<RootState> }>(
  `${NAME}/fetch-program-by-slug`,
  async (slug, thunkAPI) => {
    await thunkAPI.dispatch(audioTracksThunks.fetchNarrators());
    const locale = userSelectors.getLocale(thunkAPI.getState());
    let program = Selectors.getLocalizedProgramBySlug(thunkAPI.getState(), { slug });

    if (!program) {
      const { data } = await Axios.get<{ course: Course; tracks: Track[] }>(`/api/programs/${slug}`, {
        params: { locale },
      });

      if (!data.course) {
        return false;
      }

      program = data.course;

      thunkAPI.dispatch(courseActions.update([data.course]));
      thunkAPI.dispatch(audioTracksActions.updateTracks(data.tracks));
    }

    const programTracks = Selectors.getLocalizedProgramTracksBySlug(thunkAPI.getState(), { slug });

    if (programTracks.length !== program.tracks.length) {
      const tracksToFetch = program.tracks
        .map(({ uid }) => uid)
        .filter((trackUid) => !programTracks.find(({ uid }) => trackUid === uid));

      const { data } = await Axios.get<{ tracks: Track[] }>('/api/audio/tracks', { params: { uids: tracksToFetch } });

      thunkAPI.dispatch(audioTracksActions.updateTracks(data.tracks));
    }

    return true;
  },
);

export const trackProgramProgress = createAsyncThunk<void, string, { state: CombinedState<RootState> }>(
  `${NAME}/track-course-progress`,
  async (trackUid, thunkAPI) => {
    const user = userSelectors.getUser(thunkAPI.getState());
    if (user) {
      const program = courseSelectors.getCourseByTrackUid(thunkAPI.getState(), { uid: trackUid });
      const courseProgress = userSelectors.getUserCourseProgress(thunkAPI.getState());
      const progressInCourse = courseProgress?.[program.uid] ?? [];
      const progressSet = new Set([...progressInCourse, trackUid]);

      thunkAPI.dispatch(
        userActions.setCourseProgress(user.uid, { ...courseProgress, [program.uid]: Array.from(progressSet) }),
      );

      await thunkAPI.dispatch(userThunks.increaseWeekStreak());
    }
  },
);
