import React, { ComponentProps } from 'react';
import { ImageSet } from 'models/image';
import { Lock } from 'assets/icons';
import { Container, Content, Label, Premium, LiveLabel, IconWrapper, Title } from './featured-card.style';

export type FeaturedCardProps = ComponentProps<'article'> & {
  title: string;
  image: ImageSet;
  label?: string;
  labelIcon?: JSX.Element;
  liveLabel?: string;
  isPremium?: boolean;
  isDark?: boolean;
};

const FeaturedCard: React.FC<FeaturedCardProps> = ({
  title,
  image,
  onClick,
  label,
  labelIcon,
  liveLabel,
  isPremium,
  isDark,
}) => (
  <Container $isDark={isDark} $image={image} onClick={onClick}>
    {label && (
      <Label>
        {labelIcon && <IconWrapper>{labelIcon}</IconWrapper>}
        {label}
      </Label>
    )}
    {isPremium && (
      <Premium>
        <Lock />
      </Premium>
    )}
    <Content>
      {liveLabel && <LiveLabel>Live now</LiveLabel>}
      <Title>{title}</Title>
    </Content>
  </Container>
);

export default FeaturedCard;
