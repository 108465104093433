import React, { useCallback } from 'react';
import NextLink from 'next/link';
import { useSelector } from 'react-redux';
import {
  CollectionTypes,
  SpotlightCollection,
  SpotlightExternalCollection,
  SpotlightMeditationCollection,
  SpotlightMusicTrackCollection,
} from 'models/collection';
import { SessionSourceTypes } from 'models/session';
import { useSelectorWithProps } from 'domains/redux/utils';
import { useIntl } from 'domains/intl';
import { Selectors as userSelectors } from 'domains/user';
import { Selectors as meditationsSelectors } from 'domains/meditations';
import { Selectors as musicSelectors } from 'domains/music';
import { useMusicPlayer } from 'logic-domains/music-player';
import Grid from 'components/grid';
import Section from 'components/section';
import Carousel from 'components/carousel';
import FeaturedCard from 'components/featured-card';
import { storeSessionSource } from 'utils/session-storage-source';
import { Meditate, Music } from 'assets/icons';
import { MobileContainer, TabletContainer, Anchor } from './featured.style';

type ItemProps = {
  item: SpotlightCollection;
  isDark?: boolean;
};

const Item: React.FC<ItemProps> = ({ item, isDark }) => {
  const user = useSelector(userSelectors.getUser);
  const { formatMessage } = useIntl();
  const { createPlayer } = useMusicPlayer();
  const uid =
    (item as SpotlightMusicTrackCollection).musicTrackUid || (item as SpotlightMeditationCollection).meditationUid;
  // get the meditation, if it is a meditation.
  const meditation = useSelectorWithProps(meditationsSelectors.getMeditationByUid, { uid });
  // get the music track, if it is a music track.
  const musicTrack = useSelectorWithProps(musicSelectors.getMusicTrackByUid, { uid });
  const url = item.type === CollectionTypes.Meditation ? meditation?.url : undefined;

  const onItem = useCallback(() => {
    storeSessionSource(SessionSourceTypes.Spotlight);
    createPlayer([uid]);
  }, [createPlayer, uid]);

  if (item.type === CollectionTypes.MusicTrack) {
    return (
      <FeaturedCard
        image={item.backgroundImage}
        title={item.title}
        label={item.label}
        isDark={isDark}
        onClick={onItem}
        isPremium={!user?.premium && musicTrack?.premium}
        labelIcon={<Music />}
      />
    );
  }

  if (url) {
    return (
      <NextLink legacyBehavior href={url} passHref>
        <Anchor source={SessionSourceTypes.Spotlight}>
          <FeaturedCard
            image={item.backgroundImage}
            title={item.title}
            label={item.label}
            isPremium={!user?.premium && meditation?.premium}
            isDark={isDark}
            labelIcon={<Meditate />}
          />
        </Anchor>
      </NextLink>
    );
  }

  return (
    <Anchor href={(item as SpotlightExternalCollection).link} target="_blank" source={SessionSourceTypes.Spotlight}>
      <FeaturedCard
        image={item.backgroundImage}
        title={item.title}
        label={item.label}
        liveLabel={!!(item as SpotlightExternalCollection).link && formatMessage('live')}
        isDark={isDark}
      />
    </Anchor>
  );
};

export type FeaturedProps = {
  isDark?: boolean;
  isFirst?: boolean;
  items: SpotlightCollection[];
};

const Featured: React.FC<FeaturedProps> = ({ isDark, isFirst, items }) => (
  <Section isFirst={isFirst}>
    <MobileContainer>
      <Grid columns="mb:1">
        <Carousel isDark={isDark} columns="mb:1" hideArrows>
          {items.map((item) => {
            const key =
              (item as SpotlightMeditationCollection).meditationUid ||
              (item as SpotlightMusicTrackCollection).musicTrackUid ||
              (item as SpotlightExternalCollection).link;

            return <Item key={key} item={item} isDark={isDark} />;
          })}
        </Carousel>
      </Grid>
    </MobileContainer>
    <TabletContainer>
      <Grid columns="mb:2">
        {items.map((item) => {
          const key =
            (item as SpotlightMeditationCollection).meditationUid ||
            (item as SpotlightMusicTrackCollection).musicTrackUid ||
            (item as SpotlightExternalCollection).link;

          return <Item key={key} item={item} isDark={isDark} />;
        })}
      </Grid>
    </TabletContainer>
  </Section>
);

export default Featured;
