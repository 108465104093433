import React, { ComponentProps, useCallback } from 'react';
import { DeviceImageSet, ImageSet } from 'models/image';
import Button from 'components/button';
import { Container, Label, QuoteIcon, Title } from './quote-card.style';

export type QuoteCardProps = ComponentProps<'div'> & {
  uid?: string;
  label?: string;
  quote: string;
  image: DeviceImageSet | ImageSet;
  buttonLabel?: string;
  buttonOnClick?: (uid?: string) => void;
};

const QuoteCard: React.FC<QuoteCardProps> = ({ uid, label, quote, image, buttonOnClick, buttonLabel }) => {
  const _onClick = useCallback(() => buttonOnClick(uid), [buttonOnClick, uid]);

  return (
    <Container $image={image}>
      {label && <Label>{label}</Label>}
      <QuoteIcon />
      <Title>{quote}</Title>
      {buttonOnClick && buttonLabel && (
        <Button onClick={_onClick} isSmall isWhite isExtraTransparent>
          {buttonLabel}
        </Button>
      )}
    </Container>
  );
};

export default QuoteCard;
