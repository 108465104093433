import { rgba } from 'polished';
import styled from 'styled-components';
import { colors, fonts, typography } from 'assets/styles';
import { QuoteFilled } from 'assets/icons';
import { imageToBackgroundImageCss } from 'utils/parse-image';

export const Container = styled.div`
  ${({ $image }) => imageToBackgroundImageCss($image)};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3.2rem;
  height: 27.2rem;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  border-radius: 1.6rem;
  overflow: hidden;
  transform: translateZ(0); // safari fix for rounded borders
  transition: transform 200ms ease-in-out, filter 200ms ease-in-out;
  color: ${colors.white};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${rgba(colors.black, 0.3)};
  }
`;

export const Label = styled.span`
  position: absolute;
  left: 1rem;
  top: 1rem;
  padding: 0.4rem 0.8rem;

  border-radius: 2.8rem;
  background-color: ${rgba(colors.black, 0.4)};

  font-family: ${fonts.heading};
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.2;

  @supports ((-webkit-backdrop-filter: blur(2rem)) or (backdrop-filter: blur(2rem))) {
    background-color: ${rgba(colors.black, 0.1)};
    backdrop-filter: blur(2rem);
  }
`;

export const QuoteIcon = styled(QuoteFilled)`
  position: relative;
  padding-bottom: 1rem;
  font-size: 3.6rem;
`;

export const Title = styled.h3`
  ${typography.h3};
  position: relative;
  padding-bottom: 1.6rem;

  text-align: center;
  white-space: pre-wrap;
`;
