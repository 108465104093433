import React from 'react';
import { Afternoon, Evening, Morning } from 'assets/icons';
import { DayParts } from 'models/day-parts';

export const dayPartHeader = {
  [DayParts.Morning]: { title: 'home.header.morning', icon: <Morning /> },
  [DayParts.Afternoon]: { title: 'home.header.afternoon', icon: <Afternoon /> },
  [DayParts.Evening]: { title: 'home.header.evening', icon: <Evening /> },
};

export const dayPartHeaderLoggedIn = {
  [DayParts.Morning]: { title: 'home.header.logged-in.morning', icon: <Morning /> },
  [DayParts.Afternoon]: { title: 'home.header.logged-in.afternoon', icon: <Afternoon /> },
  [DayParts.Evening]: { title: 'home.header.logged-in.evening', icon: <Evening /> },
};

export const dayPartHeaderThisMoment = {
  [DayParts.Morning]: 'home.for-this-moment.morning.title',
  [DayParts.Afternoon]: 'home.for-this-moment.afternoon.title',
  [DayParts.Evening]: 'home.for-this-moment.evening.title',
};
