import React, { ComponentProps } from 'react';
import { Quote } from 'models/quote';
import { useIntl } from 'domains/intl';
import Grid from 'components/grid';
import Section from 'components/section';
import SectionHeader from 'components/section-header';
import Carousel from 'components/carousel';
import QuoteCard from 'components/quote-card';

export type QuoteSliderProps = ComponentProps<'section'> & {
  isDark?: boolean;
  isFirst?: boolean;
  title: string;
  moreLabel?: string;
  moreLink?: string;
  items: Quote[];
  quoteButtonLabel?: string;
  onQuoteButton?: (uid: string) => void;
};

const QuoteSlider: React.FC<QuoteSliderProps> = ({
  isDark,
  isFirst,
  title,
  moreLabel,
  moreLink,
  items,
  quoteButtonLabel,
  onQuoteButton,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Section isFirst={isFirst}>
      <Grid columns="mb:1">
        <SectionHeader title={title} linkLabel={moreLabel} link={moreLink} isDark={isDark} />
      </Grid>
      <Grid columns="mb:1">
        <Carousel columns="mb:1 tb:2 dt:3" isDark={isDark}>
          {items.map(({ uid, quote, image, date }) => {
            const quoteDate = new Date(date);

            return (
              <QuoteCard
                key={uid}
                uid={uid}
                label={`${quoteDate.getDate()} ${formatMessage(`month.title.short.${quoteDate.getMonth()}`)}`}
                quote={quote}
                image={image}
                buttonLabel={quoteButtonLabel}
                buttonOnClick={onQuoteButton}
              />
            );
          })}
        </Carousel>
      </Grid>
    </Section>
  );
};

export default QuoteSlider;
