import { CombinedState, createAsyncThunk } from '@reduxjs/toolkit';
import { Quote } from 'models/quote';
import { QuoteCategory } from 'models/quote-category';
import { State as RootState } from 'models/redux-state';
import { Selectors as userSelectors } from 'domains/user';
import { Actions as sectionsActions } from 'domains/sections';
import { Selectors as quoteSelectors, Actions as quoteActions } from 'domains/quotes';
import Axios from 'utils/axios';
import { NAME } from './constants';

export const fetchQuoteCategoryByUid = createAsyncThunk<boolean, string, { state: CombinedState<RootState> }>(
  `${NAME}/fetch-quote-categories`,
  async (uid, thunkAPI) => {
    let category = quoteSelectors.getCategoryByUid(thunkAPI.getState(), { uid });

    if (!category) {
      const { data } = await Axios.get<{ category: QuoteCategory; quotes: Quote[] }>(`/api/quotes/categories/${uid}`);

      if (!data.category) {
        return false;
      }

      category = data.category;
      thunkAPI.dispatch(quoteActions.updateCategories([category]));
      thunkAPI.dispatch(quoteActions.updateQuotes(data.quotes));
    }

    const categoryQuotes = quoteSelectors.getQuotesByCategoryUid(thunkAPI.getState(), { uid });

    if (!categoryQuotes.length) {
      const { data } = await Axios.get<{ quotes: Quote[] }>('/api/quotes', { params: { uid: category.quotes } });
      thunkAPI.dispatch(quoteActions.updateQuotes(data.quotes));
    }

    return true;
  },
);

export const fetchDailyQuotes = createAsyncThunk<void, undefined, { state: CombinedState<RootState> }>(
  `${NAME}/fetch-daily-qoutes`,
  async (_, thunkAPI) => {
    const time = new Date().getTime();
    const timezone = userSelectors.getTimezone(thunkAPI.getState());
    const offset = userSelectors.getOffset(thunkAPI.getState());
    const locale = userSelectors.getLocale(thunkAPI.getState());
    const timezoneTime = new Date(time + (offset || 0));
    const year = timezoneTime.getUTCFullYear();
    const month = (timezoneTime.getUTCMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2 });
    const day = timezoneTime.getUTCDate().toLocaleString('en-US', { minimumIntegerDigits: 2 });
    const date = `${year}-${month}-${day}`;

    const { data } = await Axios.get<{ quotes: Quote[] }>('/api/quotes/daily', { params: { locale, date, timezone } });

    thunkAPI.dispatch(
      sectionsActions.updateDailyQuotes(
        locale,
        data.quotes.map(({ uid }) => uid),
      ),
    );
    thunkAPI.dispatch(quoteActions.updateQuotes(data.quotes));
  },
);
