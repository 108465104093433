import { breakpointAndUp } from 'assets/styles';
import styled from 'styled-components';

export const MobileContainer = styled.div`
  @media (${breakpointAndUp.tablet}) {
    display: none;
  }
`;

export const TabletContainer = styled.div`
  display: none;

  @media (${breakpointAndUp.tablet}) {
    display: block;
  }
`;

export const Anchor = styled.a`
  text-decoration: none;
`;
