import { DeviceImageSet, ImageSet } from 'models/image';
import React from 'react';
import * as Styled from './link-card.style';

export type LinkCardProps = {
  url: string;
  image: DeviceImageSet | ImageSet;
  label?: string;
  external?: boolean;
};

const LinkCard: React.FC<LinkCardProps> = ({ url, image, label, external }) => {
  const Container = external ? Styled.ExternalLink : Styled.Link;

  return (
    <Container href={url} $image={image}>
      {!!label && <Styled.Label>{label}</Styled.Label>}
    </Container>
  );
};

export default LinkCard;
