import { NextPage } from 'next';
import { Thunks as appThunks } from 'domains/app';
import { Thunks as userThunks } from 'domains/user';
import { Actions as errorsActions, ErrorCodes } from 'domains/errors';
import { Thunks as logicAppThunks } from 'logic-domains/app';
import { Thunks as programsThunks } from 'logic-domains/programs';
import { Thunks as quotesThunks } from 'logic-domains/quotes';
import getInitialPropsWithStore from 'utils/get-initial-props-with-store';
import HomeView from 'views/home';

const Home: NextPage = () => <HomeView />;

Home.getInitialProps = getInitialPropsWithStore(async ({ store, req }) => {
  try {
    await Promise.all([
      store.dispatch(appThunks.fetchOnlineCount()),
      store.dispatch(logicAppThunks.fetchForYou()),
      store.dispatch(quotesThunks.fetchDailyQuotes()),
      store.dispatch(programsThunks.fetchPrograms()),
      store.dispatch(userThunks.fetchCourseProgress(req)),
    ]);
  } catch (error) {
    store.dispatch(errorsActions.setErrorCode(ErrorCodes.Unavailable));
  }

  return {};
});

export default Home;
