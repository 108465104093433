import styled from 'styled-components';
import { rgba } from 'polished';
import { breakpointAndUp, colors, typography } from 'assets/styles';
import { imageToBackgroundImageCss } from 'utils/parse-image';

export const Container = styled.article`
  ${({ $image }) => imageToBackgroundImageCss($image)}
  position: relative;
  height: 20.8rem;

  background-color: ${({ $isDark }) => ($isDark ? rgba(colors.white, 0.1) : colors.dividerLM)};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  border-radius: 0.8rem;
  overflow: hidden;
  cursor: pointer;
  transform: translateZ(0); // safari fix for rounded borders
  transition: transform 200ms ease-in-out, filter 200ms ease-in-out;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({ $isDark }) =>
      $isDark
        ? `linear-gradient(180deg, ${rgba(colors.black, 0)}, ${rgba(colors.black, 0.4)} 100%);`
        : `linear-gradient(180deg, ${rgba(colors.gradientBrown, 0)}, ${rgba(colors.gradientBrown, 0.4)} 100%)`};
  }

  @media (any-hover: hover) {
    &:hover {
      transform: translateZ(0) translateY(-0.4rem);
    }
  }

  @media (${breakpointAndUp.tabletLarge}) {
    height: 21.6rem;
  }

  @media (${breakpointAndUp.desktop}) {
    height: 27.2rem;
  }
`;

export const Label = styled.span`
  ${typography.caption};
  display: flex;
  align-items: center;
  position: absolute;
  top: 1.6rem;
  left: 1.6rem;
  padding: 0.6rem;

  color: ${colors.white};
  border-radius: 0.4rem;
  background-color: ${rgba(colors.black, 0.1)};
  backdrop-filter: blur(2rem);
`;

export const Premium = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;

  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  background-color: ${rgba(colors.black, 0.1)};
  backdrop-filter: blur(2rem);

  font-size: 1.6rem;
  color: ${colors.white};
`;

export const IconWrapper = styled.span`
  margin-right: 0.4rem;
  font-size: 1.6rem;
  line-height: 62.5%;
`;

export const Content = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0 1.6rem 1.6rem;

  @media (${breakpointAndUp.tabletLarge}) {
    padding: 0 6.8rem 1.6rem 1.6rem;
  }

  @media (${breakpointAndUp.desktop}) {
    padding: 0 8.8rem 2.4rem 2.4rem;
  }
`;

export const Title = styled.h1`
  ${typography.h2};
  color: ${colors.white};
`;

export const LiveLabel = styled.span`
  ${typography.caption};
  display: block;
  color: ${colors.tint};

  margin-bottom: 0.8rem;

  &::before {
    content: '';
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: 0.6rem;
    border-radius: 1rem;
    background-color: ${colors.tint};
  }
`;
